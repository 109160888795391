<script setup>
import { computed, ref } from "vue";
import { useStore } from "@/utils/useStore";
import { useMoney } from "@/composables/useMoney";
import i18n from "@/i18n.js";
import { GrTag, GrSkeleton } from "@/components/GrComponents";
import moment from "moment";
moment.locale("pt-br");

const { formatMoney } = useMoney();
const store = useStore();

const loading = computed(() => {
  return store.getters["getLoadingDetailContracts"];
});

const contractDetailsComputed = computed(() => {
  return store.getters["getContractDetails"];
});

const sellerComputed = computed(() => {
  return store.getters["getProfile"];
});

function dynamicVariant(status) {
  const variants = {
    paid: "success-light",
    created: "success-light",
    paid_externally: "success-light",
    pending_payment: "warning-light",
    trialing: "info-light",
    canceled: "danger-light",
    refused: "danger-light",
    ended: "danger-light",
    waiting_payment: "warning-light",
    processing: "info-light",
  };
  return variants[status];
}

function translationStatus(status) {
  const translations = {
    paid: "Pago",
    created: "Criado",
    pending_payment: "Pagamento pendente",
    trialing: "Trial",
    canceled: "Cancelado",
    refused: "Recusado",
    ended: "Encerrado",
    waiting_payment: "Aguardando pagameto",
    paid_externally: "Pago externamente",
    processing: "Processando",
  };
  return translations[status];
}

function replaceAmount(amount) {
  return formatMoney(amount, "R$");
}

function replaceCreatedAt(date) {
  const formattedDate = moment(date).format("DD/MM/YY [às] HH:mm");
  return formattedDate;
}
</script>

<template>
  <div>
    <div class="contract-summary" v-if="!loading && contractDetailsComputed.contract" data-anima="top">
      <div class="contract-details">
        <h2 class="customer-name">{{ contractDetailsComputed.client.name }}</h2>
        <p class="contract-date">
          {{
            replaceCreatedAt(
              contractDetailsComputed.contract.contract_created_at
            )
          }}
        </p>
        <GrTag
          :variant="
            dynamicVariant(contractDetailsComputed.contract.contract_status)
          "
          theme="default"
        >
          {{
            translationStatus(contractDetailsComputed.contract.contract_status)
          }}
        </GrTag>
      </div>
      <div class="contract-info">
        <p class="contract-price">
          {{ replaceAmount(contractDetailsComputed.contract.total) }}
        </p>
        <p class="contract-role">
          {{ contractDetailsComputed.contract.product }} #{{
            contractDetailsComputed.contract.product_id
          }}
        </p>
        <p class="contract-offer">
          {{ i18n.t("views.seller.contracts_new.text_017") }}:
          {{ contractDetailsComputed.contract.offer_hash }}
        </p>
      </div>
    </div>

    <GrSkeleton
      v-else
      class="contract-summary"
      sizeX="100%"
      sizeY="115px"
      radius="10px"
      :delay="1"
    />
  </div>
</template>

<style scoped lang="scss">
.contract-summary {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: var(--spacing-4);
  border-bottom: 1px solid var(--gray-50);

  .contract-details,
  .contract-info {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-2);
  }

  .customer-name {
    color: var(--dark-500);
    font-size: var(--font-3-5xl);
    font-weight: var(--weight-extrabold);
  }

  .contract-date {
    color: var(--gray-200);
    font-size: var(--font-md);
  }

  .contract-price {
    color: var(--dark-500);
    font-size: var(--font-3-5xl);
    font-weight: var(--weight-extrabold);
  }

  .contract-role,
  .contract-offer {
    color: var(--gray-200);
    font-size: var(--font-base);
  }

  @include size(lg) {
    flex-direction: column;
    gap: var(--spacing-4);
  }
}
</style>

<script setup lang="ts">
import { ref } from "vue";
import i18n from "@/i18n.js";
import { useStore } from "@/utils/useStore";
import { useToast } from "@/utils/useToast";
import {
  GrButton,
  GrModal,
  GrModalClose,
  GrModalContent,
  GrModalFooter,
  GrModalHeader,
} from "@/components/GrComponents";

const store = useStore();
const { toast } = useToast();
const isModalOpen = ref(false);
const loading = ref(false);
const canClose = ref(true);
const size = ref("md");

const props = defineProps({
  id: {
    type: Number,
    default: null,
  },
});

const closeContract = function () {
  store.dispatch("closeContract", props.id).then((res) => {
    toast(res.message, {
      title: "Contrato em cancelamento",
      variant: "success",
      autoHideDelay: 5000,
      appendToast: true,
    });
  });
};
</script>

<template>
  <GrModal
    v-model="isModalOpen"
    :close="canClose"
    renderTriggerTo="#TeleporRenderModalContractClose"
  >
    <template #open>
      <slot></slot>
    </template>

    <GrModalContent
      :size="size"
      customWidth="507"
      customHeight="478"
      :loading="loading"
      class="custom-modal-content"
    >
      <GrModalHeader />

      <div class="content-contract">
        <img
          class="icon-contract"
          src="@/assets/img/icons-contracts/close-contract-modal.svg"
        />
        <h2>{{ i18n.t("views.seller.contracts_new.modal.text_001") }}</h2>
        <p>
          {{ i18n.t("views.seller.contracts_new.modal.text_002") }}
        </p>
      </div>

      <GrModalFooter class="custom-modal-footer">
        <GrModalClose>
          <GrButton variant="gray" class="footer-button">
            {{ i18n.t("views.seller.contracts_new.modal.text_003") }}
          </GrButton>
        </GrModalClose>
        <GrButton @click="closeContract()" variant="red" class="footer-button">
          <span class="custom-color">{{
            i18n.t("views.seller.contracts_new.modal.text_004")
          }}</span>
        </GrButton>
      </GrModalFooter>
    </GrModalContent>
  </GrModal>
</template>

<style lang="scss" scoped>
.content-contract {
  display: grid;
  justify-items: center;
  text-align: center;
  margin-top: 18px;

  h2 {
    height: 30px;
    left: calc(50% - 319px / 2 + 1px);
    top: 201px;

    font-family: "Plus Jakarta Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 150%;
    display: flex;
    align-items: center;

    color: var(--old-800);
    margin-top: 48px;
  }

  p {
    width: 428px;
    height: 72px;
    left: calc(50% - 428px / 2 + 0.5px);
    top: 247px;

    font-family: "Plus Jakarta Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 160%;
    display: flex;
    align-items: center;
    text-align: center;

    color: var(--gray-200);
    margin-top: 16px;
  }
}

.footer-button {
  width: 200px;
  height: 55px;
}

.custom-color {
  color: var(--gray-white);
  font-weight: var(--weight-semibold);
}

.custom-modal-content {
  gap: 0px;
  align-items: center;
}

.custom-modal-footer {
  margin-top: 48px;
  gap: 22px;
  justify-content: center;
  position: relative;
}
</style>

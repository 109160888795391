<script setup>
import { computed, ref } from "vue";
import { useStore } from "@/utils/useStore";
import i18n from "@/i18n.js";

import { GrTag, GrSkeleton } from "@/components/GrComponents";

const store = useStore();

const loading = computed(() => {
  return store.getters["getLoadingDetailContracts"];
});

const contractDetail = computed(() => {
  return store.getters["getContractDetails"];
});

function redirect(number) {
  const url = `https://api.whatsapp.com/send?text=${number}`;
  window.open(url, "_blank");
}
</script>

<template>
  <div>
    <div
      class="contract-summary"
      v-if="!loading && contractDetail.client"
      data-anima="top"
    >
      <div class="contract-icon">
        <img
          src="@/assets/img/icons-contracts/client-contract.svg"
          alt="contracts"
        />
      </div>

      <div class="contract-content">
        <div class="contract-header">
          <div class="contract-header-data">
            <div class="contract-info">
              <p class="contract-client">
                {{ i18n.t("views.seller.contracts_new.text_034") }}
              </p>
              <h2 class="contract-client-name">
                {{ contractDetail.client.name }}
              </h2>
              <p class="contract-id">#{{ contractDetail.client.id }}</p>
            </div>

            <div class="contract-actions">
              <div
                class="contract-actions-data"
                @click="redirect(contractDetail.client.cellphone)"
              >
                <img
                  src="@/assets/img/icons-contracts/whats.svg"
                  alt="contracts"
                />
                <p>{{ i18n.t("views.seller.contracts_new.text_035") }}</p>
              </div>
            </div>
          </div>
        </div>

        <div class="contract-details">
          <div class="contract-labels">
            <p>{{ i18n.t("views.seller.contracts_new.text_036") }}</p>
            <p>{{ i18n.t("views.seller.contracts_new.text_037") }}</p>
            <p>{{ i18n.t("views.seller.contracts_new.text_038") }}</p>
          </div>
          <div class="contract-values">
            <p>{{ contractDetail.client.cellphone }}</p>
            <p>{{ contractDetail.client.email }}</p>
            <p>{{ contractDetail.client.document }}</p>
          </div>
        </div>
      </div>
    </div>

    <GrSkeleton
      v-else
      class="contract-summary"
      sizeX="100%"
      sizeY="250px"
      radius="10px"
      :delay="4"
    />
  </div>
</template>

<style scoped lang="scss">
.contract-summary {
  display: flex;
  padding-bottom: 140px;
  border-bottom: 1px solid var(--gray-50);

  .contract-icon {
    padding: 0 var(--spacing-16) 0 var(--spacing-16);

    @include size(lg) {
      padding: 0;
    }
  }

  .contract-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: var(--spacing-8);

    .contract-header {
      display: flex;
      align-items: center;

      .contract-header-data {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: var(--w-full);

        .contract-info {
          display: flex;
          flex-direction: column;
          gap: var(--spacing-1);

          .contract-client {
            color: var(--gray-200);
            font-size: var(--font-xsm);
          }

          .contract-client-name {
            color: var(--dark-500);
            font-size: var(--font-3-5xl);
            font-weight: var(--weight-extrabold);
          }

          .contract-id {
            color: var(--gray-200);
            font-size: var(--font-md);
          }
        }

        .contract-actions {
          display: flex;
          gap: var(--spacing-12);

          .contract-actions-data {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 2px;
            cursor: pointer;

            p {
              font-size: var(--font-md);
              font-weight: var(--weight-semibold);
              line-height: normal;
            }

            &:first-child p {
              color: var(--primary-600);
            }
          }
        }

        @include size(sm) {
          align-items: start;
          flex-direction: column;
          gap: var(--spacing-2);
        }
      }
    }

    .contract-details {
      display: flex;
      justify-content: space-between;

      .contract-labels,
      .contract-values {
        display: flex;
        flex-direction: column;
        gap: var(--spacing-6);
      }

      .contract-labels p,
      .contract-values p {
        color: var(--gray-200);
        font-size: var(--font-base);
      }

      .contract-values p {
        color: var(--dark-500);
        font-weight: var(--weight-semibold);
      }
    }
  }

  @include size(lg) {
    flex-direction: column;
    gap: var(--spacing-2);
  }
}
</style>

import { getCurrentInstance } from "vue";

export function useToast() {
  const instance = getCurrentInstance();

  if (!instance) throw new Error("instance must be called in setup");

  const vm = instance.proxy;

  function toast(message, options = {}) {
    vm.$bvToast.toast(message, options);
  }

  return {
    toast,
  };
}
